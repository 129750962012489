
import ConversionGuide from /* webpackChunkName: "ConversionGuide" */ "../layouts/partials/page/ConversionGuide";
import AdInPage from /* webpackChunkName: "AdInPage" */ "../layouts/partials/ads/AdInPage";
import { getPageInfo } from "~/fc/Dal/PageInfoRepo";
import ConverterTemplateFactory from "~/fc/ConverterTemplateFactory";
import InfoParser from "~/fc/InfoParser";
import Meta from "~/mixins/MetaHead";
import UrlParamHandler from "~/fc/UrlParamHandler";
import GroupTemplate from /* webpackChunkName: "GroupTemplate" */ "~/layouts/ConverterTemplates/GroupTemplate";
import ToolTemplate from /* webpackChunkName: "ToolTemplate" */ "~/layouts/ConverterTemplates/ToolTemplate";
import { fetchReviews } from "~/fc/Dal/UtilityRepo";


export default {
    name: "HomePage",
    components: {
        ConversionGuide,
        GroupTemplate,
        ToolTemplate,
        Alert: () => import(/* webpackChunkName: "Alert" */ "../components/ui/Alert"),
        TryFree: () => import(/* webpackChunkName: "TryFree" */ "../layouts/partials/page/TryFree"),
        AdsSide: () => import(/* webpackChunkName: "AdsSide" */ "../layouts/partials/ads/AdsSide"),
        AdInPage,
        ContentCard: () => import(/* webpackChunkName: "ContentCard" */ "../components/content/ContentCard"),
        ContentCardHTML: () => import(/* webpackChunkName: "ContentCardHTML" */ "../components/content/ContentCardHTML"),
        TrustPilotReviewsCollapsible: () => import(/* webpackChunkName: "Accordion" */ "~/components/ui/TrustPilotReviewsCollapsible"),
        SecurityAndCompliance: () => import(/* webpackChunkName: "SecurityAndCompliance" */ "../components/ui/SecurityAndCompliance"),
    },
    mixins: [Meta],
    layout: "default",
    async asyncData(ctx) {
        const id = "home";
        ctx.store.commit("clear");
        ctx.store.commit("items/clear");
        ctx.store.commit("advancedSetting/clearOptions");

        const [info, {data: reviews}] = await Promise.all([getPageInfo(id, ctx), fetchReviews(ctx.app)]);

        if (info && info != "Page not found") {
            info.toolPage = false;
            info.target = null;
        }
        await ctx.store.dispatch("setInfo", info);
        ctx.store.commit("updateBreadcrumbs", []);
        
        const active = ConverterTemplateFactory.setTemplate("Tool").getTemplate();

        const infoParser = new InfoParser({ $store: ctx.store });
        const conversionGuideContent = infoParser.getConversionGuide(info);

        return {
            active,
            type: "image",
            title: info.title,
            desc: info.meta_description,
            header_h1: info.header_h1,
            subtext_h1: info.subtext_h1,
            currentUrl: "",
            conversionGuideContent,
            reviews
        };
    },
    data: () => ({
        active: null,
        type: null,
        uid: 1,
        conversionGuideContent: null,
        reviews: []
    }),
    computed: {
        isMobile() {
            return this.$store.state.store.isMobile;
        },
        info() {
            return this.$store.state ? this.$store.state.info : null;
        },
        list() {
            return this.$store.state.list;
        },
        advancedSetting() {
            return this.$store.state.advancedSetting;
        },
        targetContent() {
            return this.info.target ? this.info.target.info : null;
        },
        targetDetails() {
            return typeof window !== "undefined"
                ? new InfoParser(this).getTargetContent().slice(0, 2)
                : null;
        },
        specificTools() {
            const tools = new InfoParser(this).getSpecificToolsContent();
            if (!tools.length) {
                return null;
            }
            return [
                {
                    type: "title",
                    value:
                        this.$t("specific") + this.info.target
                            ? " " + this.info.target.name
                            : " " + this.$t("converters"),
                },
                {
                    type: "paragraph",
                    value:
                        "While this page can convert any file to" +
                        (this.info.target ? " " + this.info.target.name : "") +
                        ", you may wish to visit following Converter pages that only support specific conversions",
                },
                {
                    type: "list",
                    value: {
                        column: this.isMobile ? 2 : 3,
                        items: tools,
                    },
                },
            ];
        },
        otherTools() {
            const items = new InfoParser(this).getOtherToolsContent();
            if (!items.length) {
                return null;
            }
            return [
                {
                    type: "title",
                    value:
                        "Convert" +
                        (this.info.target ? " " + this.info.target.name : "") +
                        " to other file types",
                },
                {
                    type: "paragraph",
                    value:
                        "FreeConvert.com can convert your" +
                        (this.info.target ? " " + this.info.target.name : "") +
                        " files to these other formats:",
                },
                {
                    type: "list",
                    value: {
                        column: 2,
                        items,
                    },
                },
            ];
        },
        relatedTools() {
            const tools = new InfoParser(this).getRelatedToolsContent();
            if (!tools.length) {
                return null;
            }
            return [
                {
                    type: "title",
                    value:
                        "Related" +
                        (this.info.target ? " " + this.info.target.name : "") +
                        " Converters",
                },
                {
                    type: "paragraph",
                    value: this.$t("home_specific_tools_description").replace(
                        "TARGET",
                        this.info.target ? " " + this.info.target.name : ""
                    ),
                },
                {
                    type: "list",
                    value: {
                        column: 2,
                        items: tools,
                    },
                },
            ];
        },
    },
    mounted() {
        new UrlParamHandler(this.$route).handle();
    },
    methods: {
        setData({ info, type }) {
            // eslint-disable-next-line no-undef
            this.info = { ...info };
            this.type = type;
            this.uid++;
        },
    },
};
