export default class UrlParamHandler {
    constructor(route) {
        this.route = route
    }

    checkIfAdTeamDirection () {
        const campaign = this.route.query.utm_campaign
        if(!campaign) return false
        return campaign.includes('asc')
    }

    handleAdTeamDirection () {
        const currentDate = new Date()

        const sevenDaysLater = new Date(currentDate)
        sevenDaysLater.setDate(currentDate.getDate() + 7)

        const expiryTimestamp = sevenDaysLater.getTime();
        localStorage.setItem('TelemetryUser', expiryTimestamp)
        $nuxt.$store.commit('store/setTelemetryUser', true)
    }

    handle () {
        if (this.checkIfAdTeamDirection()) this.handleAdTeamDirection()
    }
}